$(document).ready(function(){

    var marker, map_style = [{"featureType":"administrative","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"all","elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.line","elementType":"geometry.fill","stylers":[{"color":"#c9c9c9"}]},{"featureType":"transit.line","elementType":"geometry.stroke","stylers":[{"color":"#bababa"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}];    
    if($('#visit-us-map').length) {

        var lat = 51.888552,
            lng = 0.897377,
            point = {lat: lat, lng: lng},
            bounds = new google.maps.LatLngBounds();

        var results_map = new google.maps.Map(document.getElementById('visit-us-map'), {
            center: point,
            zoom: 15,
            scrollwheel: false,
            styles: map_style
        });

        marker = new google.maps.Marker({
            position: {lat: parseFloat(lat), lng: parseFloat(lng)},
            map: results_map,
            title: 'Culver Square Shopping Centre',
            icon: '/images/marker.png',
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(results_map, 'bounds_changed', function() {
            google.maps.event.trigger(results_map, 'resize');
        });

    } 
    
    // Filter form dropdown
    $('.filter-form').on('click', '.dropdown-item', function(e) {
        
        e.preventDefault();
        
        var value = $(this).data('value'),
            label = $(this).html();
        
        if($('.filter-form__filters [data-value=' + value + ']').length === 0) {
            $('.filter-form__filters .placeholder').hide();
            $('.filter-form__filters').append('<span class="filter" data-value="' + value + '">' + label + ' <i class="fa fa-close"></i></span>');
            filterResults();
        }
        
        // Remove a filter
        $('.filter-form__filters').on('click', '.filter', function(e) {
            e.preventDefault();
            $(this).remove();
            filterResults();
        });
        
    });
    
    // Filter form text search field
    $('.filter-form__surround').on('click', '.button', function(e) {
        e.preventDefault();
        textSearch();
    });
    $('.filter-form').on('submit', function(e) {
        e.preventDefault();
        textSearch();
    });
    
    // Filter the results
    function filterResults() {
        if($('.filter-form__filters .filter').length === 0) {
            $('.retailers > div').show();
            $('.filter-form__filters .placeholder').show();
            $('.search__no-results').hide();
        } else {
            
            $('.retailers > div').hide();
            var classes = $('.filter-form__filters .filter').map(function() { 
                return '.' + $(this).data('value'); 
            }).get().join('');
            console.log(classes);
            
            $('.retailers > div').each(function() {
                if($(this).is(classes)) {
                    $(this).show();
                }
            });
            
            // No results
            if($('.retailers > div:visible').length === 0) {
                $('.search__no-results').show();
            }
            
        }        
    }
    
    // Filter the results (text search)
    function textSearch() {
        var term = $('input[name=filter-form__text]').val().toLowerCase();
        if(term.length === 0) {
            $('[data-retailer]').show();
        } else {
            $('[data-retailer]').hide();
            $("[data-retailer*='" + term + "']").show();
            $("[data-job-title*='" + term + "']").show();
        }
    }
    
    // Mobile menu social icons
    $('.mobile-panel').on('click', '.social', function(e) {
        e.preventDefault();
        if($('.header-container_wrap .header-icons').is(':visible')) {
            $('.header-container_wrap .header-icons').hide(300);
        } else {
            $('.header-container_wrap .header-icons').show(300);
        }
    });
    
    // Mobile menu "Shopping Centre Information" dropdown
    $('.shopping-centre-information').on('click', function(e) {
        e.preventDefault();
        if($('.header-container_wrap .header-container__top').is(':visible')) {
            $('.header-container__top').hide(300);
            $('.shopping-centre-information .fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
        } else {
            $('.header-container__top').show(300);
            $('.shopping-centre-information .fa').removeClass('fa-chevron-down').addClass('fa-chevron-up');
        }
    });
    
    // Expanding footer menu links on mobile
    if($('.site-footer .footer-block h6 i').is(':visible')) {
        $('.site-footer .footer-block').on('click', 'h6', function() {
            var content = $(this).siblings('p'),
                icon = $(this).find('i');
            if(content.is(':visible')) {
                content.hide();
                icon.removeClass('fa-angle-double-up').addClass('fa-angle-double-right');
            } else {
                content.show();
                icon.removeClass('fa-angle-double-right').addClass('fa-angle-double-up');
            }
        });
    }
    
    $('[data-toggle="tooltip"]').tooltip();
    
    $.cookieBar({
        message: 'This site uses cookies to offer you a better browsing experience. If you agree to our use of cookies please click "Yes, I agree". To find out more click the "Cookie Policy" button.',
        policyButton: true,
        policyText: 'Cookie Policy',
        policyURL: 'https://www.culversquare.co.uk/privacy-cookies',
        acceptText: 'Yes, I agree'
    });
    
});